import styled from "styled-components"
import { Link } from "gatsby"

const SubFolderContainer = styled(Link)`
  height: 50px;
  border: var(--border);
  background: var(--primaryLightVariant);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  word-wrap: normal;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
`
export default SubFolderContainer
