import React from "react"
import { graphql } from "gatsby"

import BreadcrumbContainer from "../styles/BreadcrumbContainer"
import SubFolderContainer from "../styles/SubFolderContainer"
import SubFolderHeading from "../styles/SubFolderHeading"
import SubFolderHeadingTitle from "../styles/SubFolderHeadingTitle"
import SubFolderIcons from "../styles/SubFolderIcons"

import getSubFolders from "../utils/getSubFolders"

export default function homePage({ data: { markdownNodes } }) {
  const { nodes } = markdownNodes

  const subFolders = getSubFolders([...nodes], 1)

  return (
    <div>
      <BreadcrumbContainer>/</BreadcrumbContainer>
      {subFolders.map(slug => (
        <SubFolderContainer key={slug} to={slug}>
          <SubFolderHeading>
            <SubFolderIcons />
            <SubFolderHeadingTitle>{slug.split("/")[1]}</SubFolderHeadingTitle>
          </SubFolderHeading>
        </SubFolderContainer>
      ))}
    </div>
  )
}

export const query = graphql`
  query indexPage {
    markdownNodes: allMarkdownRemark {
      nodes {
        timeToRead
        frontmatter {
          slug
          title
          name
          breadcrumb {
            slug
          }
        }
        html
        id
      }
    }
  }
`
