import styled from "styled-components"
import { FaFolder } from "react-icons/fa"

const SubFolderIcons = styled(FaFolder)`
  color: var(--primaryVariant);
  margin-right: 0.25rem;
  font-size: var(--doc-font-size);
`

export default SubFolderIcons
