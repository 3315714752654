import styled from "styled-components"

const BreadcrumbContainer = styled.div`
  height: 1.44rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1.25rem;
  color: var(--primary);
  border-bottom: var(--border);
  padding-bottom: 2rem;
`

export default BreadcrumbContainer
