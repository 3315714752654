export default function getSubFolders(nodes, depth) {
  const subFoldersSet = new Set()

  /**
   * Problem: having to figure out which node is a folder opposed to an article.
   * Folder will have a breadcrumb length longer than the depth past in.
   * Filter out all nodes that have a greater than or less than depth, as those are articles.
   */
  const folderNodes = nodes.filter(node => {
    return node.frontmatter.slug.match(/\//g || []).length > depth
  })

  folderNodes.forEach(subFolderNode => {
    const slug = subFolderNode.frontmatter.slug
      .split("/")
      .slice(0, depth + 1)
      .join("/")

    if (!slug.includes("/secure")) {
      subFoldersSet.add(slug)
    }
  })

  return [...subFoldersSet]
}
